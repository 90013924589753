<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="SettingsIcon" />
          <span>Servis Randevusu</span>
        </template>
        <service-form v-if="!dataLoading" />
        <loading
          v-else
          class="mt-2"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
    <add-car :refresh-data="getCars" />
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Customer from '@/views/Crm/View/Customer.vue'
import ServiceForm from '@/views/Service/Edit/ServiceForm.vue'
import AddCar from '@/views/Service/components/AddCar.vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'Edit',
  components: {
    Loading,
    BTabs,
    BTab,
    Customer,
    ServiceForm,
    AddCar,
  },
  computed: {
    dataLoading() {
      return this.$store.getters['serviceTracing/getDataLoading']
    },
  },
  created() {
    this.getServiceDetail()
  },
  methods: {
    getServiceDetail() {
      this.$store.dispatch('serviceTracing/serviceView', this.$route.params.id)
        .then(res => {
          if (res.id_com_cars) {
            this.$store.dispatch('cars/carView', res.id_com_cars)
          }
          if (res.id_com_customer) {
            this.$store.dispatch('customers/customerView', res.id_com_customer)
            this.getCars(res.id_com_customer)
          }
          if (res.id) {
            this.getMeets()
            this.getUsers(res)
          }
        })
    },
    getCars(id) {
      this.$store.dispatch('cars/carsList', {
        where: {
          'com_cars.id_com_customer': id,
        },
      })
    },
    getMeets() {
      this.$store.dispatch('meets/meetsList', {
        select: [
          'com_meet.id AS id',
          'com_meet.title AS title',
        ],
      })
    },
    getUsers(data) {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS name',
          'com_brand.name AS brand',
          'com_location.title AS location',
          'com_user_type.title AS user_type',
        ],
        where: {
          'com_user.status': 1,
          'com_user.id_com_location': data.id_com_location,
          'com_user.id_com_brand': data.id_com_brand,
        },
        where_in: [
          [
            'com_user.id_com_department', [3, 9],
          ],
          [
            'com_user.id_com_user_type', [5, 19],
          ],
        ],
        order_by: [
          'com_user.id_com_location,com_user.id_com_brand', 'ASC',
        ],
      })
    },
  },
}
</script>
