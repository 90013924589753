<template>
  <b-form-group
    label="Lokasyon"
    label-for="id_com_location"
  >
    <validation-provider
      #default="{ errors }"
      name="Lokasyon"
      rules="required"
    >
      <v-select
        v-model="dataItem.id_com_location"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Giriniz"
        :disabled="!dataItem.id_com_brand"
        @input="getUsers"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'ServiceTracingLocationSelectCard',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['locations/getLocations']
    },
    dataItem() {
      return this.$store.getters['serviceTracing/getService']
    },
  },
  created() {
    localize('tr')
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('locations/locationsList', {
        select: [
          'com_location.id AS id',
          'com_location.title AS title',
        ],
        where: {
          'com_location.service_status': 1,
        },
      })
    },
    getUsers() {
      const userParams = {
        select: [
          'com_user.id AS id',
          'com_user.name AS name',
          'com_brand.name AS brand',
          'com_location.title AS location',
          'com_user_type.title AS user_type',
        ],
        where: {
          'com_user.status': 1,
          'com_user.id_com_location': this.dataItem.id_com_location,
        },
        where_in: [
          [
            'com_user.id_com_department', [3, 9, 26],
          ],
          [
            'com_user.id_com_user_type', [5, 19],
          ],
        ],
        order_by: [
          'com_user.id_com_location,com_user.id_com_brand', 'ASC',
        ],
      }
      this.dataItem.id_com_user = null
      this.$store.commit('serviceTracing/SET_TIMES', {
        data: [],
        serviceData: [],
      })
      this.dataItem.appointment_date = null
      this.dataItem.appointment_time = null
      if (this.dataItem.id_com_brand === '5') {
        userParams.where_in.push([
          'com_user.id_com_brand', [this.dataItem.id_com_brand, 2],
        ])
      } else {
        userParams.where['com_user.id_com_brand'] = this.dataItem.id_com_brand
      }
      this.$store.dispatch('users/usersList', userParams)
    },
  },
}
</script>
